ClientsControllerModule.controller 'ClientController'
 ,
  ['$scope', '$http' , '$timeout' ,'Client' , 'Division', 'SubDivision' ,'User', ($scope, $http, $timeout, Client, Division, SubDivision , User)->
    # bootstrap
    $scope.clients = []
    $scope.loading = false
    user_roles = { client_user: 'Client User' ,  client_manager: 'Client Manager', client_admin: 'Client Admin' }
    set_user_roles = (model)->
      if model.roles.indexOf('client_admin') == 0
        model.roles = [ 'client_admin' ]
      else if model.roles is 'client_manager' or model.roles.indexOf('client_manager') > -1
        model.roles = [ 'client_manager' ]
      else
        model.roles = [ 'client_user' ]

    # todo Use interceptor or rails resource config options to do this
    division_children_to_resources = (division)->
      subdivisions = []
      for subdivision in division.subdivisions
        subdivisions.push(new SubDivision(subdivision))
      division.subdivisions = subdivisions
      subdivisions

    client_children_to_resources = (client)->
      divisions = []
      for division in client.divisions
        d = new Division(division)
        d.subdivisions = division_children_to_resources(division)
        divisions.push(d)
      users = []
      for user in client.users
        u = new User(user)
        u.available_roles = user_roles
        users.push(u)
      client.users = users
      client.divisions = divisions

    for item in window.clients
      client = new Client(item)
      client_children_to_resources(client)
      $scope.clients.push(client)

    $scope.client = $scope.clients[0]

    if window.client_id
      for c in $scope.clients
        if parseInt(c.id) is parseInt(window.client_id)
          $scope.client = c
          break

    unless $scope.client
      $scope.client = new Client(type: 'client')

    $scope.id = $scope.client.id
    $scope.division = undefined
    $scope.subdivision = undefined

    client_rating_attributes = ['rating1Label' , 'rating2Label' , 'rating3Label' , 'rating4Label']
    client_base_attributes   = [  'subdivisionLabel' , 'divisionLabel' , 'name' ]

    editable_attributes =
      client: ["rating4Label", "rating3Label", "rating2Label", "rating1Label", "subdivisionLabel", "divisionLabel", "name"]
      division: [ 'name' ]
      subdivision: [ 'name' ]
      user: ["roles", "email", "lastname", "firstname", "password", "password_confirmation"]

    backups =
      client:
        set:  (model) -> @['store'] = $.extend({} , model)
        store:  $.extend({} , $scope.client)
      division:
        set:  (model) -> @['store'] = $.extend({} , model)
        store: undefined
      subdivision:
        set:  (model) -> @['store'] = $.extend({} , model)
        store: undefined
      user:
        set:  (model) -> @['store'] = $.extend({} , model)
        store: undefined

    set_editable_attributes = (model , flag , attribute)->
      attrs = editable_attributes[model.type]
      if model.type is 'client'
        if attribute is 'name'
          attrs = client_base_attributes
        else if attribute is 'rating1Label'
          attrs = client_rating_attributes

      for attribute in attrs
        model.editable[attribute] = flag

    changed = (model)->
      did_change = false

      if model.id
        for attribute in editable_attributes[model.type]
          if backups[model.type].store[attribute] != model[attribute] or model.errors && model.errors[attribute]
            did_change = true
            break
      else
        did_change = true
      did_change

    $scope.toTitleCase = (str)->
      return str.replace /(?:^|\s)\w/g, (match)->
        return match.toUpperCase()

    to_camel = (str)->
      s = ""
      for word , index in str.split('_')
        if index is 0
          s += word
        else
          s +=  $scope.toTitleCase(word)
      s

    camelizeErrors = (errors)->
      h = {}
      for key , value of errors
        h[to_camel(key)] = value
      return h

    backup_model = (model)->
      if !backups[model.type].store or backups[model.type].store.id != model.id
        backups[model.type].store = $.extend({} , model)

    update_model = (model , attribute)->
      if changed(model)
        $scope.loading = true
        model.save().then (m)->
          m.updated =  {}
          for attr , flag of m.editable
            m.updated[attr] = true
          m.editable = {}
          m.errors = {}
          if m.type is 'client'
            client_children_to_resources(m)
            if $scope.clients.indexOf(m) is -1
              $scope.clients.push m
          if m.type is 'division'
            division_children_to_resources(m)

          backups[m.type].set(m)
          $scope.loading = false
          $timeout ()->
            for attr , flag of m.updated
              m.updated[attr] = false
           ,
            1000
         ,
          (object)->
            $scope.loading = false
            model.errors = camelizeErrors(object.data.errors)
            model.editable[attribute] = true
      else
        set_editable_attributes(model, false)

    $scope.new_client = ()->
      c = new Client()
      c.type = 'client'
      c.editable = {}
      for attr in editable_attributes[c.type]
        c.editable[attr] = true
      $scope.client = c

    $scope.new_child = (parent , association , child_class)->
      klass = eval child_class
      parent_id = "#{parent.type}_id"
      model = new klass()
      model[parent_id] = parent.id
      model.type = child_class.toLowerCase()
      if model.type is 'user'
        model.available_roles = user_roles
      model.roles = [ 'client_user' ]
      model.editable = {}
      for attr in editable_attributes[model.type]
        model.editable[attr] = true
      if !parent[association]
        parent[association] = []
      parent[association].push(model)

    $scope.delete_client = (client)->
      do_delete = confirm("Are you sure you want to remove #{client.name || 'this ' + client.type }?")
      remove_client = ()->
        $scope.clients.splice($scope.clients.indexOf(client) , 1)
        if $scope.clients.length
          $scope.client = $scope.clients[0]
        else
          $scope.new_client()

      if do_delete
        if client.id
          client.delete().then (m)->
            remove_client()
        else
          remove_client()

    $scope.delete_child = (associated_objects, model)->
      remove_model = ()->
        index = associated_objects.indexOf(model)
        associated_objects.splice(index , 1)
      do_delete = confirm("Are you sure you want to remove #{model.name || 'this ' + model.type }?")
      if do_delete
        if model.id
          model.delete().then (m)->
            remove_model()
        else
          remove_model()

    $scope.edit = ($event , model , attribute)->
      backup_model(model)

      model.editable = {} unless model.editable
      # user role selection
      unless $event
        if model.editable[attribute]
          set_user_roles(model)
          update_model(model , attribute)
        return model.editable[attribute] = !model.editable[attribute]
      # enter on input
      if $event.keyCode is 13
        if model.editable[attribute]
          update_model(model , attribute)
      #  edit link
      else if $event.type is 'click'
        if $event.currentTarget.tagName is 'A'
          ero = false
          for error , messages of model.errors
            ero = true
            break

          if ero
            $scope[model.type] = $.extend({} , backups[model.type].store) if backups[model.type].store
            $scope[model.type].updated = {}
            $scope[model.type].errors = {}
          else
            if model.editable[attribute]
              update_model(model , attribute)
            else
              flag = false
              for attr in editable_attributes[model.type]
                if model.editable[attr]
                  flag = true
              set_editable_attributes(model , !flag , attribute)
        else
          set_editable_attributes(model , !model.editable[attribute] , attribute)

    $scope.select_client = (id)->
      for client in $scope.clients when client.id == id
        $scope.client = client
        break
      true

    $scope.edit_client_base_attributes = (client)->
      client.editable = {} unless client.editable
      set_editable_attributes(client , true , 'name')

    $scope.edit_rating_definitions = (client)->
      client.editable = {} unless client.editable
      set_editable_attributes(client , true , 'rating1Label')

    $scope.edit_user = (user)->
      user.editable = {} unless user.editable
      backup_model(user)

      save = false
      for attribute in editable_attributes.user
        if user.editable[attribute]
          save = true
      if save
        update_model(user , 'name')
      else
        set_editable_attributes(user , !save , 'name')

    $scope.user_roles = (user)->
      $scope.toTitleCase(user.roles.join(' , ').split('_').join(' ') )

  ]
