AssessmentsControllerModule.controller 'ClientController'
 ,
  ['$scope', '$http', '$timeout', 'Client', 'ClientFramework', 'Division', 'SubDivision', 'AssessmentGroup', '$filter', ($scope, $http, $timeout, Client, ClientFramework, Division, SubDivision, AssessmentGroup , $filter)->
    # bootstrap
    $scope.clients = []
    $scope.selected_divisions = []
    $scope.selected_subdivisions = []
    $scope.landing_page_text = ""
    $scope.master_frameworks = window.master_frameworks

    for template in window.email_templates
      if template.email_type == "invitation"
        $scope.invitation_email_body = template.body
      else if template.email_type == "notification"
        $scope.notification_email_body = template.body

    $scope.loading = false
    $scope.creating = false

    for c in window.clients
      client = new Client(c)
      $scope.clients.push(client)

    filtered_frameworks = ()->
      arr = $filter("filter")($scope.client_frameworks , $scope.name || '')
      if $scope.master_framework?.id
        return $filter('masterFrameworkFilter')(arr , $scope.master_framework.id)
      else
        return arr

    $scope.get_client_frameworks = ()->
      if $scope.client
        $scope.loading = true
        $scope.client_divisions = []
        $scope.division_subdivisions = []

        ClientFramework.query({clientId: $scope.client.id }).then (frameworks)->
          $scope.client_frameworks = frameworks
          $scope.set_group_name()
          setDivisions()
        ,
          (error)->
            $scope.loading = false

    $scope.set_group_name = ()->
      if $scope.master_framework
        $scope.group_name = "#{$scope.client.name} - #{$scope.master_framework.name}"
      else
        $scope.group_name = "#{$scope.client.name} - Unspecified Frameworks"

    $scope.checkIfComplete = (framework)->
      if framework.complete == 'incomplete'
        return true

    setDivisions = () ->
      Division.query({clientId: $scope.client.id }).then (divisions)->
        for d in divisions
          division = new Division(d)
          $scope.client_divisions.push(division)

        division_ids = []
        for d in $scope.client_divisions
          division_ids.push(d.id)

        SubDivision.query({divisionIds: division_ids.join(',') }).then (subdivisions)->
          for sd in subdivisions
            subdivision = new SubDivision(sd)
            $scope.division_subdivisions.push(subdivision)

          show_division_and_subdivisions()
          $scope.loading = false
        ,
          (error)->
            console.log('Error getting subdivisions')
      ,
        (error)->
          console.log('Error getting divisions')

    $scope.select_all = ()->
      $scope.select_all_flag = !$scope.select_all_flag
      for framework in filtered_frameworks()
        if !$scope.checkIfComplete(framework)
          framework.included = $scope.select_all_flag
      show_division_and_subdivisions()

    $scope.select_framework = (framework)->
      framework.included = !framework.included
      show_division_and_subdivisions()

    show_division_and_subdivisions = ->
      division_ids = selected_division_ids()
      subdivision_ids = selected_subdivision_ids()

      if division_ids.length > 0
        $scope.selected_divisions = []
        for d in $scope.client_divisions
          if division_ids.includes(d.id)
            $scope.selected_divisions.push(d.name)
      else
        $scope.selected_divisions = []

      if subdivision_ids.length > 0
        $scope.selected_subdivisions = []
        for sd in $scope.division_subdivisions
          if subdivision_ids.includes(sd.id)
            $scope.selected_subdivisions.push(sd.name)
      else
        $scope.selected_subdivisions = []

    selected_framework_ids = ()->
      fw_ids = []
      for framework in filtered_frameworks() || []
        if framework.included
          fw_ids.push(framework.id)
      fw_ids

    selected_division_ids = ()->
      division_ids = []
      for framework in filtered_frameworks() || []
        if framework.included
          division_ids.push(framework.divisionId)
      division_ids

    selected_subdivision_ids = ()->
      subdivision_ids = []
      for framework in filtered_frameworks() || []
        if framework.included
          subdivision_ids.push(framework.subdivisionId)
      subdivision_ids

    $scope.create = ()->
      ids = selected_framework_ids()
      if !$scope.client
        alert("Please select a client.")
        return false
      else if !ids.length
        alert("Please select a client framework.")
        return false
      else if ($scope.landing_page_text.length == 0)
        alert("Please enter some Landing page text.")
        return false

      $scope.creating = true

      $scope.assessment_group = new AssessmentGroup
        client_framework_ids: ids
        name: $scope.group_name
        client_id: $scope.client.id
        master_framework_id: $scope.master_framework?.id
        notification_template_body: $scope.notification_email_body
        invitation_template_body: $scope.invitation_email_body
        landing_page_text: $scope.landing_page_text

      $scope.assessment_group.errors = {}
      $scope.assessment_group.save().then (ag)->
        $scope.creating = false
        window.location = "/assessment_groups/#{ ag.id }"
       ,
        (error)->
          $scope.creating = false
          $scope.assessment_group.errors = error.data.errors
  ]
